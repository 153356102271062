import React, { FC } from "react";
import { SocialMediaItem } from "@/components/basic/SocialMediaItem";
import { socialsPoland } from "@/utils/companySocialMedia";

export const FooterSocialMedia: FC = () => (
  <div className="l-4-cols-desktop">
    <ul className="footer-social-media d-flex-box flex-box-space-between">
      <SocialMediaItem
        alt="linkedin"
        url={socialsPoland.linkedin}
        iconUrl="/images/social/linkedin_brand_primary.svg"
      />
      <SocialMediaItem
        alt="facebook"
        url={socialsPoland.facebook}
        iconUrl="/images/social/facebook_brand_primary.svg"
      />
      <SocialMediaItem
        alt="instagram"
        url={socialsPoland.instagram}
        iconUrl="/images/social/instagram_brand_primary.svg"
      />
      <SocialMediaItem
        alt="xing"
        url={socialsPoland.xing}
        iconUrl="/images/social/xing_brand_primary.svg"
      />
      <SocialMediaItem
        alt="twitter"
        url={socialsPoland.twitter}
        iconUrl="/images/social/twitter_brand_primary.svg"
      />
      <SocialMediaItem
        alt="youtube"
        url={socialsPoland.youtube}
        iconUrl="/images/social/youtube_brand_primary.svg"
      />
    </ul>
  </div>
);
