export const socialsPoland = {
  linkedin: "https://www.linkedin.com/company/anixe-polska-sp--z-o-o-/",
  facebook: "https://www.facebook.com/ANIXEpl/",
  instagram: "https://www.instagram.com/anixecompany/",
  twitter: "https://twitter.com/anixepl",
  youtube: "https://www.youtube.com/channel/UCLb9f0BWJ-j-yPJMGrla49g",
  xing: "https://www.xing.com/pages/anixe",
};

export const socialsGreece = {
  linkedin: "https://www.linkedin.com/company/anixe-polska-sp--z-o-o-/",
  facebook: "https://www.facebook.com/Project-Beagle-623379171141925/",
  twitter: "https://twitter.com/anixepl",
};
