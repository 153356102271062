import React, { FC } from "react";
import { contactPoland, contactGreece } from "@/utils/companyContactData";

export const FooterCompanyLocations: FC = () => (
  <div className="l-6-cols-desktop">
    <address className="footer-location l-4-cols-tablet l-bleed-before">
      <strong className="country">Headquarters: Poland</strong>
      <br />
      {contactPoland.streetAndNumber}
      <br />
      {contactPoland.postcodeAndCity}
    </address>
    <address className="footer-location l-4-cols-tablet">
      <strong className="country">Greece</strong>
      <br />
      {contactGreece.streetAndNumber}
      <br />
      {contactGreece.postcodeAndCity}
    </address>
  </div>
);
