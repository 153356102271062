export const contactPoland = {
  name: "ANIXE Polska Sp. z o.o.",
  streetAndNumber: "Grabiszynska 251a",
  postcodeAndCity: "53-234 Wroclaw",
  country: "Poland",
  phoneSales: "tel: +48 71 364 79 50",
  phoneSupport: "tel: +48 71 364 79 48",
  fax: "fax: +48 71 363 21 02",
};

export const contactGreece = {
  name: "ANIXE Technology HELLAS Single Member PC",
  streetAndNumber: "Leoforos Kifisias 115",
  building: "Building the Orbit Athens",
  postcodeAndCity: "11524 Athens",
  country: "Greece",
  phone: "tel: +30 211 1200 300",
};
